<template>
	<div>
		<div class="header">
			<div
				class="top top_mafi "
				:class="{ trans: is_trans }"
			><!--trans-->
				<div class="util shadow">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ item_board.board_name ? item_board.board_name : !is_trans ? item_cartel.cartl_name : '' }}</h2>
				<div class="util shadow">
					<a
						@click="$emit('to', {name: 'mafia048', params: { idx: $route.params.idx }})"
						class="img_button2"
					><span class="hide">{{ $language.common.search }}</span></a>
					<button
						@click="is_side = !is_side"
						class="ico_ham"
					><span class="hide">{{ $language.common.menu }}</span></button>
				</div>
			</div>
		</div>
		<div
			v-if="!$route.params.code"
			class="cartel_cover"
		>
			<div class="cover_background">
				<img
					v-if="item_cartel.cartl_img_url"
					:src="item_cartel.cartl_img_url" alt="Register Cover Image"
				>
			</div>
			<div class="cover_content clear">
				<div class="title_area">
					<h2>{{  item_cartel.cartl_name }}</h2>
				</div>
				<div class="cartel_area clear">
					<div class="badge_area">
						<span class="badge_30 " :class="'badge_30_' + item_cartel.type + '_bl'">
							<em class="hide">{{  item_cartel.type }}</em>
						</span>
					</div>
					<div class="text_area">
						<div class="level">{{ item_cartel.cartl_level_name }}</div>
						<div class="member">
							멤버수<b>{{  item_cartel.current_member_count }}</b>
						</div>
					</div>
				</div>
				<div class="cartel_btn_area">
					<a
						v-if="is_join_confirm"
						@click="$emit('to', { name: 'mafia057', params: { idx: $route.params.idx }})"
					>
						<div
							class="m_invite"
						>
							<span>{{ $language.common.invite_member }}</span>
						</div>
					</a>
					<a
						v-else-if="is_join_wait"
					>
						<div
							class="btn_s btn_fill_blue"
						>
							<span>{{  item_cartel.cartl_entry_state_name }}</span>
						</div>
					</a>
					<a
						v-else-if="is_join_cancel"
					>
						<div
							class="btn_s btn_fill_gray"
						>
							<span>{{  item_cartel.cartl_entry_state_name }}</span>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div
			v-if="!$route.params.code"
			class="c_notice"
		>
			<div class="container">
				<div class="row">
					<h3 @click="$emit('to', { name: 'mafia127', params: { idx: $route.params.idx, code: $language.common.bbs_code_notice }})" class="tit">{{ $language.common.notice }}</h3>
					<div class="c_notice_list">
						<ul>
							<template
								v-if="items_cartel_notice.length > 0"
							>
								<li
									v-for="(notice, index) in items_cartel_notice"
									:key="'notice_' + index"

									@click="toNotice(notice)"
								>
									<em>{{ $language.common.announcement }}</em>
									<span>{{  notice.post_title }}</span>
								</li>
							</template>
							<li
								v-else
							>
								<em>{{ $language.common.announcement }}</em>
								<span>no data</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div
			class="post_wrap pb-100" :class="{ 'mt-50': $route.params.code}"
			ref="post_list_item_view"
		>
			<div class="container">
				<template
					v-if="items_cartel_bbs.length > 0"
				>
					<div
						v-for="(item, index) in list_bbs"
						:key="'item_' + index"
						class="post_layout_view"
					>
						<div class="contents_card">
							<article class="post_list_item_view">
								<!-- 게시글 작성자 -->
								<div class="post_author">
									<div class="left">
										<a>
									<span class="profile_inner">
										<img
											v-if="item.profle_nft_card_img_url"
											:src="$request.upload_url(item.profle_nft_card_img_url)"
										/>
										<img
											v-else
											:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
										>
									</span>
											<strong class="name"><em v-if="item.is_block" class="state-block">{{ $language.common.cut_off }}</em>{{ item.post_member_nickname }}</strong>
										</a>
									</div>
									<div class="right">
										<span class="time">{{  item.time_t }}</span>
										<button
											@click="onMore(item, index)"
											class="post_set"><span class="hide">{{ $language.common.setting }}</span></button>
									</div>
								</div>

								<!-- //게시글 작성자 -->
								<!-- 게시글 메인 영역 -->
								<div class="post_main_view" >
									<!-- 비주얼 영역 -->
									<div class="photo_view">
										<!-- 슬라이드 -->

										<div class="photo_collage_slide">

											<carousel
												:perPage="1"
											>
												<slide
													v-for="(file, f_index) in item.post_file_list"
													:key="'item_' + index + '_file_' + f_index"
													class="photo_collage_view"
												>
													<img
														v-if="file.appendix_file_div_code.indexOf('CA01000001') > -1"
														:src="$request.upload_url(file.file_url)"
														@click="toDetail(item)"
													/>
													<video
														v-if="file.appendix_file_div_code.indexOf('CA01000002') > -1"
														width="100%"
														controls
													>
														<source
															:src="file.file_url"
														>
													</video>
													<div
														v-if="file.appendix_file_div_code.indexOf('CA01000003') > -1"
														class="text_view" style="position: static !important;"
													>
														<figure class="audio_wrap">
															<div class="audio_bg">
																<span class="hide">{{ $language.common.soundBackgroundImg }}</span>
															</div>
															<video
																width="100%"
																controls
																style="position: absolute; bottom: 0"
															>
																<source
																	:src="file.file_url"
																>
															</video>
														</figure>
													</div>
												</slide>
											</carousel>
										</div>
										<!-- 슬라이드 -->
									</div>
									<!-- //비주얼 영역 -->
									<!-- 텍스트 영역 -->
									<div
										class="text_view"
										@click="toDetail(item)"
									>
								<span class="text_area">
								{{  item.contents }}
								</span>
										<!-- TODO : 3줄 이상의 경우 더보기 버튼 생성  -->
										<span
											class="text_more"
										>더보기</span>
									</div>
									<!-- //텍스트 영역 -->
								</div>
								<!-- //게시글 메인 영역 -->
								<!-- 게시글 공감 댓글 -->
								<div class="post_count_view">
									<div class="post_count">
										<div class="postcountleft">
                      {{ $language.common.sympathy }} <span>{{  item.sympaty_count }}</span>
										</div>
										<div class="postcountright">
                      {{ $language.common.comment }} <span>{{  item.comment_count }}</span>
										</div>
									</div>
									<div class="post_add" style="position: relative">
										<div class="post_add_box">
											<div class="add_col">
												<!-- TODO : 공감 버튼을 누를경우 on 추가 -->
												<button
													@click="$set(item, 'is_emoji', !item.is_emoji)"
												><span class="add_status add_empathy " :class="{ on: item.is_empathy}">{{ $language.common.sympathy }}</span></button>
											</div>
											<div class="add_col">
												<a
													@click="$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})"
												><span class="add_status add_comment">{{ $language.common.comment }}</span></a>
											</div>
										</div>

										<div
											v-if="item.is_emoji"
											class="emotion_view" style="top: -25px; left: 10px"
										>
											<div class="emotion_select_view">
												<div class="list" style="display: flex; padding: 5px">
													<!-- 선택된 표정의 버튼에는 on 클래스 추가 -->
													<button
														v-for="(emoji, index) in icons_emoji"
														:key="'emoji_icon_' + index"
														type="button" class="btn_emotionitem" data-emotion-type="best"
														:style="item.sympaty_icon_code == emoji.code ? 'background-color: #bbb; border-radius: 50px': ''"
														@click="postEmoji(item, emoji)"
													>
												<span
													class="faceicon_30  em_select_btn"
													:class="'f_' + emoji.type + '_30'"

												>{{  emoji.txt }}</span>
													</button>
												</div>
												<span class="list_arrow"></span>
											</div>
										</div>
									</div>
								</div>
								<!-- //게시글 공감 댓글 -->
							</article>
						</div>
					</div>
				</template>
				<div
					v-else
					class="list_none"
					style="overflow: hidden; height: 100%;"
					:style="$route.params.code ? 'padding-top: 100%' : ''"
				>
					<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
					<span>{{ $language.common.NotFoundArticle }}</span>
				</div>
			</div>
		</div>



		<div
			v-if="is_join"
			class="cartel_join"
		>
			<button @click="toJoin">
				<div class="cartel_join_btn">
					<span>{{ $language.common.cartel }}<br><em>{{ $language.common.join }}</em></span>

				</div>
			</button>
		</div>

		<div
			v-if="is_mine"
			class="cartel_write"
		>
			<button
				@click="$emit('to', {name: 'mafia1271', params: {idx: $route.params.idx, code: $route.params.bulletin_number}})"
			>
				<div class="cartel_write_btn">
					<span class="hide">{{ $language.word.write }}</span>
				</div>
			</button>
		</div>

		<mafia055
			v-if="is_side"
			:user="user"
			:cartel="item_my_cartel"

			@to="to"
			@click="setMount"
			@cancel="is_side = false"
		></mafia055>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_cartel"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			style="z-index: 1003"
		>
		</mafia064>

		<popup_confirm
			v-if="is_delete"

			@click="deleteBbs"
			@cancel="is_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.notice.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{ $language.notice.article_delete_message }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.notice.article_delete_message_confirm }}</template>
		</popup_confirm>
	</div>
</template>


<script>

import { Carousel, Slide } from 'vue-carousel';
import mafia055 from '@/view/Cartel/mafia055'
import mafia064 from "@/view/Cartel/mafia064";
import popup_confirm from "@/view/Layout/PopupConfirm";
import mafia098 from '@/view/Cartel/mafia098'

export default {
	name: 'mafia0491'
	, props: ['user']
	, components: {mafia064, mafia055, Carousel, Slide, popup_confirm, mafia098}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: this.$language.common.cartel
				, type: 'cartel'
				, not_header: true
				, not_footer: true
				, from: this.$route.params.code ? this.$route.params.from : 'main'
				, params: this.$route.params
			}
			, item_cartel: {}
			, items_cartel: []
			, items_cartel_notice: []
			, items_cartel_bbs: []
			, idx_cartel: this.$route.params.idx
			, is_side: false
			, item_my_cartel: {}
			, is_more: false
			, item_more: {}
			, is_trans: true
			, item_board: {}
			, is_delete: false
			, is_098: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
				, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
				, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
				, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
				, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
				, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
			]
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
		}
	}
	, computed: {
		is_mine: function(){
			let t = false
			if(this.is_join_confirm){
				t = true
			}
			return t
		}
		, list_bbs: function(){
			let self = this
			return this.items_cartel_bbs.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				item.time_t = self.$date.getTimeStory(item.registdate)
				return item
			})
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_join_confirm: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true
					break
			}
			return t
		}
		, is_join_cancel: function (){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true
					break;
			}
			return t
		}
		, is_join_wait: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300001':
				case 'CA00300002':
					t = true
					break;
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.items_cartel_notice = result.data.notice_mttrs_list
					this.items_cartel_bbs = result.data.post_list

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

					this.item_search.page_number++

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelCheer: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelNotice: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelBbs: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_bbs_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
			}
		}
		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, onDelete: function(){
			this.is_more = false
			this.is_delete = true
		}
		, handleClick(event, ref) {
			console.log(this.$refs, event, ref)
			this.$refs[ref][event]();
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}
		, setMount: function(e){
			this.$emit('to', e)
			if(e.name == 'mafia049'){
				this.mount()
			}
		}
		, mount: async function(){
			console.log('mount', this.$route.params.from, this.program.from)
			this.program.from = this.$route.params.from ? this.$route.params.from : 'main'
			this.$emit('onLoad', this.program)
			console.log('mount', this.$route.params.from, this.program.from)

			this.is_side = false

			await this.getCartel()

			if(this.$route.params.code == 'CA00700001'){
				await this.getCartelNotice()
			}else if(this.$route.params.code == 'CA00700001') {
				await this.getCartelCheer()
			}else if(this.$route.params.code){
				await this.getCartelBbs()
			}

		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.sympaty_icon_code = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}

		, scrollListen: function(){
			if(this.mode == 'reply'){
				return false
			}else{

				let w = window.innerHeight
				let b = this.$refs.post_list_item_view.scrollHeight
				let t = document.body.scrollTop

				let max = b - w
				if(max <= 0){
					max = 0
				}

				if(t > max){
					this.getCartelBbs()
				}
			}
		}
		, removeScroll: function(){
			// console.log('removeScroll')
			document.body.removeEventListener('scroll', this.scrollListen)
		}
		, getScroll: function(){
			console.log('getScroll', this.$refs.post_list_item_view)
			this.h = this.$refs.post_list_item_view.scrollHeight
			document.body.addEventListener('scroll', this.scrollListen);
		}
		, toNotice(notice){
			this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: notice.board_type_code ? notice.board_type_code: this.$route.params.code, b_id: notice.board_number, bbs_id: notice.bulletin_number}})
		}
		, toDetail(item){
			this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, to: function(params){
			this.$emit('to', params)
		}
		, toJoin: function(){
			if(this.user.member_number){
				this.$bus.$emit('to', { name: 'mafia050', params: { idx: this.idx_cartel}} )
			}else{
				this.$bus.$emit('onLogin')
			}
		}
	}
	,async  created() {
		await this.mount()

		let self = this

		if(!this.$route.params.code){
			document.body.addEventListener('scroll', function(){
				if(document.body.scrollTop > 0){
					self.is_trans = false
				}else{
					self.is_trans = true
				}
			});
		}else{
			this.is_trans = false
		}

		// console.log('049', this.$route.params)
	}
}
</script>

<style>
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }
</style>